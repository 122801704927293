<template>
  <div>
    <h1 class="text-h4">Управление пользователями</h1>
    <v-divider class="my-2"></v-divider>
    <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="article, article, actions"
    ></v-skeleton-loader>
    <v-card v-show="!loading" class="mt-2">
      <v-card-title>
        Редактирование пользователя
      </v-card-title>
      <v-card-text class="d-sm-flex align-start text-body-2">
        <div class="d-flex flex-column mr-2 align-center">
          <v-avatar size="90">
            <v-img :src="userId | avatarUrl('thumb', hasImage, true)"></v-img>
          </v-avatar>
          <br/>
          <div class="justify-center">
            <v-btn v-can:access="{type:'acl', id:'Admin'}" small @click="uploadAvatar">Сменить аватар</v-btn>
          </div>
          <my-dialog-upload-avatar
            v-model="showDialogUploadAvatar"
            :user-id="userId"
            :has-image="hasImage"
            :on-success-upload="function(){fetchUser({userId})}"
          ></my-dialog-upload-avatar>
        </div>
        <div class="flex-grow-1">
          <v-form
            ref="form"
            v-model="form.formValidity"
            :disabled="!$can('access', { type: 'acl', id: 'Admin' })"
            @submit.prevent="submitUserUpdateForm"
          >
            <v-text-field
              v-model="form.data.username"
              label="Логин"
              :rules="form.rules.username"
            ></v-text-field>
            <v-text-field
              v-model="form.data.email"
              label="Email"
              :rules="form.rules.email"
            ></v-text-field>
            <v-text-field
              v-model="form.data.fullName"
              label="Полное имя"
              :rules="form.rules.fullName"
            ></v-text-field>
            <v-combobox
              v-model="form.data.status"
              label="Статус пользователя"
              hint="Укажите необходимый статус пользователя"
              persistent-hint
              chips
              :items="statusOptions"
              :loading="loading"
              :rules="form.rules.status"
            ></v-combobox>
            <v-combobox
              v-model="form.data.role"
              label="Роли пользователя"
              hint="Укажите необходимые роли пользователя"
              persistent-hint
              chips
              clearable
              :items="roleOptions"
              :loading="loading"
              :rules="form.rules.role"
            ></v-combobox>
            <div class="mt-6">
              <v-btn
                v-can:access="{type:'acl', id:'Admin'}"
                color="primary"
                :disabled="!form.formValidity"
                type="submit"
              >Сохранить
              </v-btn>
              <v-btn class="ml-1" :to="{name:'users.list'}">Назад</v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
    <v-expansion-panels v-model="panelActions" class="mt-2" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="title">Действия</v-expansion-panel-header>
        <v-expansion-panel-content class="text-body-2">
          <div class="mb2">
            <div class="subtitle-1">Изменить пароль</div>
            <div class="mb-2">Вам будет предложено ввести новый пароль</div>
            <v-btn class="my-2" @click.stop="showPasswordDialog = true">
              <v-icon left>
                mdi-lock
              </v-icon>
              Сменить пароль
            </v-btn>
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="mb2">
            <div class="subtitle-1 error--text">Удалить аккаунт</div>
            <div class="mb-2">Это действие необратимо, после того как вы удалите аккаунт, его данные будут недоступны.
            </div>
            <v-btn color="error" class="my-2" @click.stop="deleteAccountDialog.show = true">Удалить</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <meta-view
      :id="metadata.id"
      :loading="loading"
      :created-at="metadata.createdAt"
      :updated-at="metadata.updatedAt"
    ></meta-view>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="deleteAccountDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление аккаунта</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление аккаунта?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteAccount">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteAccountDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPasswordDialog" persistent max-width="600px">
      <v-card>
        <v-form v-model="formChangePassword.formValidity" @submit.prevent="changePassword">
          <v-card-title class="title">Смена пароля</v-card-title>
          <v-card-text class="my-2">
            <my-input-password
              v-model="formChangePassword.data.newPassword1"
              :rules="formChangePassword.rules.newPassword1"
              type="password"
              label="Новый пароль"
              autocomplete="new-password"
            ></my-input-password>
            <my-input-password
              v-model="formChangePassword.data.newPassword2"
              autocomplete="off"
              :rules="validateNewPassword"
              type="password"
              label="Повторите ввод нового пароля"
            ></my-input-password>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" :disabled="!formChangePassword.formValidity" type="submit">
              Отправить
            </v-btn>
            <v-btn text @click="showPasswordDialog = false">
              Отмена
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import UserUpdateForm from '@/model/user/UserUpdateForm'
import { ruleEmail, ruleRequired } from '@/utils/FormRules'
import MyDialogUploadAvatar from '@/components/ui/dialog/MyDialogUploadAvatar'
import MetaView from '@/components/ui/MetaView.vue'
import MyInputPassword from '@/components/ui/input/MyInputPassword.vue'
import UserChangePasswordForm from '@/model/user/UserChangePasswordForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'UserEditPage',
  components: { MyInputPassword, MetaView, MyDialogUploadAvatar },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true
    this.fetchUser({ userId: to.params.userId })
      .then(() => {
        this.updateFromStore()
        this.loading = false
      })
      .then(() => next())
      .catch((error) => {
        console.warn(error)
        ToastService.error('Что-то пошло не так')
      })
  },
  data: function () {
    return {
      loading: true,
      busy: false,
      panelActions: [],
      panelMetadata: [0],
      form: {
        formValidity: false,
        data: {
          username: '',
          email: '',
          fullName: '',
          status: null,
          role: null
        },
        rules: {
          username: [ruleRequired()],
          email: [ruleRequired(), ruleEmail()],
          fullName: [ruleRequired()],
          status: [ruleRequired()],
          role: [ruleRequired()]
        }
      },
      metadata: {
        id: '',
        isDeleted: false,
        createdAt: '',
        updatedAt: ''
      },
      statusOptions: [],
      roleOptions: [],

      showDialogUploadAvatar: false,

      deleteAccountDialog: {
        show: false,
        value: []
      },
      showPasswordDialog: false,
      formChangePassword: {
        data: {
          newPassword1: '',
          newPassword2: ''
        },
        rules: {
          newPassword1: [
            (value) => !!value || 'Поле обязательно должно быть заполнено',
            (value) => !!value && value.length >= 6 || 'Пароль должен быть не менее 6 символов'
          ]
        }
      }
    }
  },
  computed: {
    ...mapState('user', {
      storedUser: 'user'
    }),
    hasImage() {
      return !!this.storedUser && this.storedUser.hasImage
    },
    validateNewPassword() {
      return [
        (v) => (this.formChangePassword.data.newPassword1 === this.formChangePassword.data.newPassword2 || 'Пароли не совпадают')
      ]
    }
  },
  created() {
    const statusOptions = [
      { text: 'Активный', value: 'Active' },
      { text: 'Заблокирован', value: 'Blocked' },
      { text: 'В ожидании', value: 'Pending' }
    ]

    this.statusOptions = statusOptions

    const roleOptions = [
      { text: 'Пользователь', value: 'User' },
      { text: 'Флорист', value: 'Florist' },
      { text: 'Менеджер', value: 'Manager' },
      { text: 'Админ', value: 'Admin' }
    ]

    this.roleOptions = roleOptions

    this.fetchUser({ userId: this.userId })
      .then(() => {
        this.updateFromStore()
        this.loading = false
      })
      .catch((error) => {
        console.warn(error)
        ToastService.error('Что-то пошло не так')
      })
  },
  methods: {
    ...mapActions('user', ['fetchUser', 'updateUser', 'changeUserPassword', 'deleteUser']),
    updateFromStore() {
      this.form.data.username = this.storedUser.username
      this.form.data.email = this.storedUser.email
      this.form.data.fullName = this.storedUser.fullName
      this.form.data.status = this.statusOptions.find((o) => o.value === this.storedUser.status)
      this.form.data.role = this.roleOptions.find((o) => o.value === this.storedUser.role)

      this.metadata.id = this.storedUser.id
      this.metadata.createdAt = this.storedUser.meta.createdAt
      this.metadata.updatedAt = this.storedUser.meta.updatedAt
      this.metadata.isDeleted = this.storedUser.meta.isDeleted
    },
    uploadAvatar() {
      this.showDialogUploadAvatar = true
    },
    submitUserUpdateForm() {
      this.busy = true

      const userUpdateForm = new UserUpdateForm({
        id: this.userId,
        username: this.form.data.username,
        email: this.form.data.email,
        fullName: this.form.data.fullName,
        status: this.form.data.status.value,
        role: this.form.data.role.value
      })

      this.updateUser({ form: userUpdateForm })
        .then(() => {
          this.updateFromStore()
        })
        .then(() => {
          ToastService.success('Данные сохранены')
        })
        .then(() => {
          this.$router.push({ name: 'users.list' })
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.busy = false
        })
    },
    deleteAccount() {
      this.deleteAccountDialog.show = false
      this.loading = true

      this.deleteUser({ id: this.userId })
        .then((ids) => {

          ToastService.success('Данные сохранены')

          return this.$router.push({ name: 'users.list' })
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.loading = false
        })
    },
    changePassword() {
      this.showPasswordDialog = false
      this.loading = true

      const form = new UserChangePasswordForm({
        id: this.userId,
        password: this.formChangePassword.data.newPassword1
      })

      this.changeUserPassword({ form })
        .then((id) => {
          this.formChangePassword.data.newPassword1 = null
          this.formChangePassword.data.newPassword2 = null
          ToastService.success('Данные сохранены')
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Редактирование пользователя'
      }
    }
  }
}
</script>

<style scoped>

</style>
