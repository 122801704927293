export default class UserUpdateForm {
  constructor(data) {
    this.id = data?.id
    this.username = data?.username
    this.email = data?.email
    this.fullName = data?.fullName
    this.role = data?.role
    this.status = data?.status
  }
}
